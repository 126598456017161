<template>
    <Editor :url="url" :fields="fields"
            icon="mdi-crosshairs-question"
            testo-list="Requisiti"
            testo-insert="Nuovo requisito"
            testo-update="Modifica requisito"
            testo-delete="Vuoi cancellare il requisito?">
        <template #item.s_livelli="{item}">
            <div>
                <v-chip class="ml-2" small :key="livello.id" v-for="livello in item.s_livelli">{{ livello.descrizione }}</v-chip>
            </div>
        </template>
        <template #form="{instance}">
            <Relation v-model="instance.categoria" url="/backend/autocomplete/categoria" label="Categoria"></Relation>
            <v-text-field v-model="instance.descrizione" label="Descrizione"></v-text-field>
            <Relation multiple v-model="instance.livelli" url="/backend/autocomplete/livello"
                      label="Livelli"></Relation>
            <v-textarea label="Note default" v-model="instance.note_default"></v-textarea>
            <v-textarea label="Documenti Richiesti" v-model="instance.documenti_richiesti"></v-textarea>
        </template>
    </Editor>
</template>


<script>
import Editor from "@/components/Editor";
import Relation from "@/components/Relation";

export default {
    data: () => ({
        fields: [
            {text: 'Codice', value: 's_categoria.codice'},
            {text: 'Categoria', value: 's_categoria.nome'},
            {text: 'Descrizione', value: 'descrizione'},
            {text: 'Livelli', value: 's_livelli'},
        ]
    }),
    computed: {
        url() {
            return '/checklist/requisito/';
        }
    },
    components: {
        Editor,
        Relation,
    }
}
</script>