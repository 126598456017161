<template>
    <v-card>
        <v-card-title>
            <h3>{{ testoList }}</h3>
            <v-spacer></v-spacer>
            <div v-if="globalActions && filteredGlobalActions.length === 1">
                <v-btn class="mx-2"
                       color="primary"
                       :key="0"
                       @click="execAction(filteredGlobalActions[0].key)"
                >
                    {{ filteredGlobalActions[0].label }}
                </v-btn>
            </div>
            <div v-else>
                <v-menu offset-y v-if="filteredGlobalActions.length !== 0">
                    <template #activator="{ on, attrs }">
                        <v-btn class="mx-2"
                               color="primary"
                               v-bind="attrs"
                               v-on="on"
                               :disabled="selected.length === 0"
                        >
                            <v-icon>mdi-menu-down</v-icon>
                            Azioni ({{ selected.length }})
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in filteredGlobalActions"
                            :key="index"
                            @click="execAction(item.key)"
                        >
                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <slot name="top-before"></slot>
            <slot name="top">
                <v-btn v-if="!noInsert" color="success" @click="createItem" class="mx-2">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </slot>
            <slot name="top-after"></slot>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="data.results"
            :items-per-page="10"
            :loading="loading"
            :options.sync="options"
            :server-items-length="data.count"
            class="elevation-1"
            :footer-props="{'items-per-page-options': [10, 20, 50, 100]}"
            :show-select="checkbox"
            v-model="selected"
        >
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope"/>
            </template>
            <template #top>
                <slot name="extra"></slot>
                <v-container>
                    <v-row v-if="filters">
                        <v-col cols="2" :key="item.field" v-for="item in filters">
                            <v-select v-if="item.options"
                                      :items="item.options"
                                      :label="item.label"
                                      v-model="search[item.field]"
                                      @change="refresh"
                            ></v-select>
                            <DatePicker
                                v-else-if="item.type === 'date'"
                                v-model="search[item.field]"
                                @change="refresh"
                                :label="item.label"
                            ></DatePicker>
                            <DatePicker
                                v-else-if="item.type === 'daterange'"
                                v-model="search[item.field]"
                                @change="refresh"
                                :label="item.label"
                            ></DatePicker>
                            <v-checkbox
                                v-else-if="item.type === 'boolean'"
                                v-model="search[item.field]"
                                @change="refresh"
                                :label="item.label"
                            ></v-checkbox>
                            <Relation v-else-if="item.type === 'ajax'"
                                      v-model="search[item.field]"
                                      :url="item.url" :label="item.label"
                                      @change="refresh" :filters="item.filter">
                            </Relation>
                            <v-text-field v-else
                                          v-model="search[item.field]"
                                          class="mr-3"
                                          @keyup.enter="refresh"
                                          :label="item.label"
                            ></v-text-field>

                        </v-col>
                        <v-col align-self="center">
                            <v-btn color="primary" dark small @click="refresh" class="mr-2">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                            <v-btn color="secondary" dark small @click="search={};refresh()">
                                <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #item.actions="{ item }">
                <div class="d-flex justify-end">
                    <slot name="actions" :item="item"></slot>
                    <v-btn v-if="!noUpdate" color="warning" small class="mr-2" @click="editItem(item)">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <Confirm v-if="!noDelete" :message="testoDelete" @confirm="deleteItem(item)">
                        <template #activator="{on, attrs}">
                            <v-btn v-on="on" v-bind="attrs" color="error" small>
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </Confirm>
                </div>
            </template>
        </v-data-table>
        <v-dialog :value="dialogEdit" @click:outside="close" @keydown.esc="close" max-width="500px">
            <v-card class="pa-3">
                <v-card-title>
                    <v-card color="blue" dark elevation="5" class="mr-5 text-center" width="40" height="40">
                        <v-icon>{{ icon }}</v-icon>
                    </v-card>
                    <span class="headline" v-if="this.instance.id">{{ testoUpdate }}</span>
                    <span class="headline" v-else>{{ testoInsert }}</span>
                </v-card-title>
                <v-card-text>
                    <div v-show="hasErrors">
                        <v-alert v-if="errors.non_field_errors">
                            <div :key="message" v-for="message in errors.non_field_errors">{{ message }}</div>
                        </v-alert>
                    </div>
                    <slot name="form" :instance="instance" :errors="errors"></slot>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="gray" @click="close">Annulla</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-show="hasErrors" @click="showErrors" color="error">
                        <v-icon>mdi-alert</v-icon>
                    </v-btn>
                    <v-btn color="primary" @click="save">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="dialogErrors" @click:outside="dialogErrors=false" @keydown.esc="dialogErrors=false"
                  width="unset">
            <v-card class="pa-3">
                <v-card-title class="headline">Errori di validazione</v-card-title>
                <v-card-text>
                    <v-simple-table dense>
                        <template #default>
                            <tbody>
                            <tr :key="field" v-for="(messages, field) in errors">
                                <td class="font-weight-bold">{{ field }}</td>
                                <td>
                                    <div :key="message" v-for="message in messages">{{ message }}</div>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeErrors">Chiudi</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Confirm from "@/components/Confirm";
import DatePicker from "@/components/DatePicker";
import Relation from "@/components/Relation";

export default {
    data: () => ({
        instance: {},
        dialogEdit: false,
        data: {
            rows: [],
            totalRows: 0
        },
        options: {},
        search: {},
        loading: false,
        dialogErrors: false,
        errors: {},
        selected: [],
    }),
    props: {
        url: String,
        fields: Array,
        title: String,
        checkbox: Boolean,
        globalActions: Array,
        filters: Array,
        args: Object,
        icon: String,
        testoList: String,
        testoInsert: String,
        testoUpdate: String,
        testoDelete: String,
        noInsert: Boolean,
        noUpdate: Boolean,
        noDelete: Boolean,
    },
    watch: {
        options: {
            async handler() {
                await this.refresh();
            },
            deep: true,
        },
    },
    computed: {
        headers() {
            return [...this.fields, {text: '', value: 'actions', sortable: false, align: 'end'}];
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
        filteredGlobalActions() {
            if (this.globalActions === undefined)
                return []
            return this.globalActions.filter(row => !row.auth || this.$auth(row.auth));
        },

    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        createItem() {
            this.instance = {};
            this.dialogEdit = true;
        },
        editItem(row) {
            this.instance = {...row};
            this.dialogEdit = true;
        },
        getOptions() {
            const opt = this.options;
            let ordering = [];
            opt['sortBy'].forEach((field, i) => {
                if (opt['sortDesc'][i]) field = '-' + field;
                ordering.push(field.replaceAll('.', '__'));
            });
            return {
                page: opt.page,
                itemsPerPage: opt.itemsPerPage,
                ordering: ordering.join(','),
                ...this.args,
            };
        },
        showErrors() {
            this.dialogErrors = true;
        },
        closeErrors() {
            this.dialogErrors = false;
        },
        getParams() {
            return {
                ...this.getOptions(),
                ...this.search
            }
        },
        async refresh(clear = false) {
            if (clear)
                this.selected = [];
            this.loading = true;
            const res = await this.$http.get(this.url, this.getParams());
            if (res.ok)
                this.$set(this, 'data', res.result.data);
            this.loading = false;
            this.$emit('refresh');
        },
        async save() {
            this.errors = {};
            let res = {};
            if (this.instance.id) {
                let url = `${this.url}${this.instance.id}/`;
                res = await this.$http.patch(url, this.instance);
            } else {
                res = await this.$http.post(this.url, this.instance);
            }
            if (!res.ok) {
                this.errors = res.result;
                return;
            }
            this.notify('Modifica completata!');
            this.close();
            await this.refresh();
        },
        async deleteItem(item) {
            let url = `${this.url}${item.id}/`;
            await this.$http.delete(url)
            this.notify('Cancellazione completata!');
            this.close();
            await this.refresh();
        },
        close() {
            this.errors = {};
            this.dialogEdit = false;
        },
        execAction(item) {
            this.$emit('globalAction', {action: item, selected: this.selected});
        },

    },
    components: {
        DatePicker,
        Confirm,
        Relation
    }
}
</script>